<template>
    <div class="min-h-screen flex items-center justify-center bg-slate-500 p-4">
      <div class="bg-white p-8 rounded shadow-md w-full max-w-md">
        <div class="flex justify-end mb-4">
          <img @click="setLanguage('en')" src="https://assets.brian.com.co/web/flags/usa.png" alt="English" class="w-6 h-6 cursor-pointer mr-2">
          <img @click="setLanguage('es')" src="https://assets.brian.com.co/web/flags/spain.png" alt="Spanish" class="w-6 h-6 cursor-pointer">
        </div>
        <h1 @click="incrementClickCount" class="text-2xl font-bold mb-4">{{ translations[language].title }}</h1>
  
        <label for="start-date" class="block text-sm font-medium text-gray-700">{{ translations[language].startDate }}</label>
        <input v-model="startDate" type="date" id="start-date" class="mt-1 mb-4 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
        <input v-model="startTime" type="time" step="1" id="start-time" class="mt-1 mb-4 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
  
        <label for="end-date" class="block text-sm font-medium text-gray-700">{{ translations[language].endDate }}</label>
        <input v-model="endDate" type="date" id="end-date" class="mt-1 mb-4 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
        <input v-model="endTime" type="time" step="1" id="end-time" class="mt-1 mb-4 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
  
        <button @click="calculateDifference" class="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition duration-300">
          {{ translations[language].calculate }}
        </button>
  
        <div v-if="result" class="mt-6">
          <p><strong>{{ translations[language].months }}:</strong> {{ formatNumber(result.months) }}</p>
          <p><strong>{{ translations[language].weeks }}:</strong> {{ formatNumber(result.weeks) }}</p>
          <p><strong>{{ translations[language].days }}:</strong> {{ formatNumber(result.days) }}</p>
          <p><strong>{{ translations[language].hours }}:</strong> {{ formatNumber(result.hours) }}</p>
          <p><strong>{{ translations[language].minutes }}:</strong> {{ formatNumber(result.minutes) }}</p>
          <p><strong>{{ translations[language].seconds }}:</strong> {{ formatNumber(result.seconds) }}</p>
          <p><strong>{{ translations[language].milliseconds }}:</strong> {{ formatNumber(result.milliseconds) }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import Cookies from 'js-cookie';
  
  export default {
    name: 'DateCalculator',
    setup() {
      const startDate = ref('');
      const endDate = ref('');
      const startTime = ref('00:00:00');
      const endTime = ref(new Date().toLocaleTimeString('it-IT', { hour12: false }));
      const result = ref(null);
      const language = ref(Cookies.get('language') || 'en');
      const translations = {
        en: {
          title: 'Date Calculator',
          startDate: 'Start Date',
          endDate: 'End Date',
          calculate: 'Calculate',
          months: 'Months',
          weeks: 'Weeks',
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes',
          seconds: 'Seconds',
          milliseconds: 'Milliseconds',
          alert: 'Please enter both dates',
        },
        es: {
          title: 'Calculadora de Fechas',
          startDate: 'Fecha Inicial',
          endDate: 'Fecha Final',
          calculate: 'Calcular',
          months: 'Meses',
          weeks: 'Semanas',
          days: 'Días',
          hours: 'Horas',
          minutes: 'Minutos',
          seconds: 'Segundos',
          milliseconds: 'Milisegundos',
          alert: 'Por favor, ingresa ambas fechas',
        }
      };
  
      const clickCount = ref(0);
      let clickTimeout = null;
  
      const incrementClickCount = () => {
        clickCount.value += 1;
  
        if (clickCount.value === 5) {
          setEasterEgg();
          clickCount.value = 0;
        }
  
        if (clickTimeout) clearTimeout(clickTimeout);
        clickTimeout = setTimeout(() => {
          clickCount.value = 0;
        }, 1000);
      };
  
      const setLanguage = (lang) => {
        language.value = lang;
        Cookies.set('language', lang, { expires: 365 });
      };
  
      const calculateDifference = () => {
        if (!startDate.value || !endDate.value) {
          alert(translations[language.value].alert);
          return;
        }
  
        const start = new Date(`${startDate.value}T${startTime.value}`);
        const end = new Date(`${endDate.value}T${endTime.value}`);
        const diff = end.getTime() - start.getTime();
  
        const milliseconds = diff;
        const seconds = diff / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;
        const weeks = days / 7;
        const months = days / 30.44;
  
        result.value = {
          months: Math.floor(months),
          weeks: Math.floor(weeks),
          days: Math.floor(days),
          hours: Math.floor(hours),
          minutes: Math.floor(minutes),
          seconds: Math.floor(seconds),
          milliseconds: milliseconds
        };
      };
  
      const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(number).replace(/,/g, '.');
      };
  
      const setEasterEgg = () => {
        startDate.value = '2012-11-29';
        startTime.value = '00:00:00';
        const now = new Date();
        endDate.value = now.toISOString().split('T')[0];
        endTime.value = now.toLocaleTimeString('it-IT', { hour12: false });
        calculateDifference();
      };
  
      return {
        startDate,
        endDate,
        startTime,
        endTime,
        result,
        language,
        translations,
        setLanguage,
        calculateDifference,
        formatNumber,
        incrementClickCount,
      };
    }
  };
  </script>
  
  <style scoped>
  /* Aquí puedes agregar estilos específicos para este componente si es necesario */
  </style>
  