<template>
  <DateCalculator></DateCalculator>
</template>

<script>
import DateCalculator from './components/DateCalculator.vue'

export default {
  name: 'App',
  components: {
    DateCalculator
  }
}
</script>

<style></style>
